/*

  custom.css - All your own CSS goodies
  Version 1.x
  by you

  Use your own styles here! 

  Cheers, 
  ThemeVillain 
  
*/
.folio-page-image {
  /* height: calc(100vw/2); */
  height: 100%;
  background-size: cover;
  background-position: center;
  border: 10px solid white;
  transition: all .2s;
  overflow: hidden;
}

.folio-page-image>img:hover {
  cursor: pointer;
  opacity: .7;
}

.folio-desc-item {
  border: 2px solid var(--accent);
  color: var(--accent);
  text-transform: uppercase;
  border-radius: 5px;
  padding: .5rem 1rem;
  font-weight: 700;
  margin: 0 5px;
}

.folio-desc-item:last-child {
  margin: 0 !important;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
/* .caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
} */

/* Add Animation - Zoom in the Modal */
/* .modal-content, .caption {
  animation-name: zoom;
  animation-duration: 0.6s;
} */

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 90px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  z-index: 2;
}

.close:hover,
.close:focus {
  color: var(--accent);
  text-decoration: none;
  cursor: pointer;
}

a.thumb {
  position: relative;
  text-decoration: none;
  display: grid;
  align-items: center;
  justify-content: center;
  float: left;
  color: #fff;
  overflow: hidden;
}

a.thumb img {
  display: block;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 100%;
}

.folio-page-modal--image {
  height: 80vh;
  width: auto;
  transition: all .2s;
}

.zoom {
  transform: scale(3) translate(33%, 33%);
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }

  .folio-page-modal--image {
    height: auto;
    width: 80vw;
  }
}

.logo-desktop { display: block; }
.logo-mobile { display: none; }
.logo-footer {
  width: 25%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .logo-desktop { display: none; }
  .logo-mobile { display: block; }
}

.decor-light {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 15vw;
  height: auto;
}

#quotes {
  position: relative;
}

.route-item {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-multi-carousel-dot button {
  border: 2px solid var(--accent) !important;
}

.react-multi-carousel-dot--active button {
  border: 2px solid var(--accent) !important;
  background: var(--accent) !important;
}

.footer-list {
  width: fit-content;
  margin: 0 auto;
}

@media screen and (max-width: 640px) {
  .footer-list {
    width: 100%;
    margin: 0;
    text-align: center;
    padding-bottom: 1rem;
  }
}

footer {
  background: var(--dark);
  color: white;
}

footer hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255 , 0.2);
  margin: 1em 0;
  padding: 0;; 
}

footer p {
  color: white !important;
}

.scroll-to-top-button {
  width: 50px !important;
  height: 50px !important;
  background: var(--accent) !important;
}

.scroll-to-top-button svg {
  transform: translateY(3px);
  transition: all .2s;
}

.scroll-to-top-button:hover svg {
  transform: translateY(-1px) scale(1.1);
}

.project-category {
  display: block;
  color: var(--accent);
  border: 3px solid var(--accent);
  border-radius: 5px;
  padding: 10px 20px;
  width: fit-content;
}